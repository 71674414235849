@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import "colors";

@font-face {font-family: "Nassim";
  src: url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.eot");
  src: url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.eot?#iefix") format("embedded-opentype"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.woff2") format("woff2"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.woff") format("woff"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.ttf") format("truetype"),
  url("//db.onlinewebfonts.com/t/29e9a3f5cf847428382058f505969b17.svg#Nassim") format("svg"); }

[dir=ltr] body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: $foreground;
}
[dir=rtl] body {
  font-family: "Nassim", "Helvetica Neue", sans-serif;
  color: $foreground;
}
html {
  font-size: 100%;
}
html, body {
  height: 100%;
}
.popup-ct {
  position: relative;
}
svg {
  flex-shrink: 0;
}
.nowrap {
  white-space: nowrap;
}
/*a {
  border-bottom: 1px solid;
  padding-bottom: 3px;

  &:hover {
    border-bottom: 0;
  }
}*/
#app {
  height: 100%;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.red{
  color: red;
}
.green {
  color :green;
}

.phone-number {
  direction: ltr;
}

@media screen and (min-width: 320px) { body {font-size:0.5rem;} }
@media screen and (min-width: 640px) { body {font-size:0.9rem;} }
@media screen and (min-width:960px) { body {font-size:1.1rem;} }
@media screen and (min-width:1100px) { body {font-size:1.3rem;} }


// styaling of tabe for all app


.searchPanelFotamt{
 // color: #000000;

  background :  #fff8e6fb;
  font-weight: bold;
  border-radius: 15px 15px 0px 0px;

 
  
}


.totalTabl{
  color: #000000;
  cursor: pointer;
  background :  #fff8e6fb;
  font-weight: bold;
  
  text-align: center;
 
  
}


.ant-table-thead .ant-table-cell {
  background :  #fff8e6fb;
  font-weight: bold;
 
  border-color: #eecf626f;
  text-align-last: center;

 
  

}
.ant-table-row .ant-table-cell {
  background-color: #ffffff;
  
 text-align: center;
 
  line-height: normal;
 border-color: #eecf626f;
}
.ant-input  {
  
 //background-color: rgb(36, 189, 16);
  
 border-radius: 10px;
 border-color: #7159036f;
  text-align: center;
  
}
.ant-input-number  {
  border-radius: 10px;
 

  text-align-last: center;
 }


 .ant-input-password  {
  border-radius: 10px;
 // background-color: rgb(36, 189, 16);
  //border-color: orange;
 }

 .ant-select  {
  border-radius: 10px;
  background-color: rgb(36, 189, 16);
  
 
 }
.ant-select-item {
  color: rgb(1, 11, 71);
  //background: transparent;
  border-radius: 10px;
 
}




.ant-select-dropdown {
  font-size: 13px;
  border-radius: 10px;
  background-color: rgb(255, 250, 243);
 
}

.ant-form-item-control {
  float: right;
  height: 36px;
 
  border-radius: 4px;
  border: 1px solid $white;
  box-sizing: border-box;
  }
 