@import '../../../../app/styles/style';

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.spine {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}


.textlable{
  width: 200%;
  
}
.ant-transfer-list-header-title {
  text-align: center;
}

.editable-cell {
  position: relative;
}

.frams-format{
  border: 2px solid #ebd097;
  background: rgba(255, 220, 188, 0.24);
  text-align: center;
  border-radius: 25px;
  margin: 5px;
  padding: 15px;


}
.car-frams-format{
  border: 2px solid #ebd097;
  background: rgba(255, 220, 188, 0.24);
  text-align: center;
  border-radius: 25px;
  margin: 5px;
  padding: 15px;
  width: 100%;


}
.button-format{
  
  background:  #24a0ed;
  
  border-radius: 10px;
  width: auto;
  opacity: 1;
  margin-top: 40px;
  margin-inline-end: -250px;


}


.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}