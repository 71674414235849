@import "../../../app/styles/colors";

.ant-layout-sider {
  background:  #fccb81;
  
  .ant-layout-sider-children {
    background:  #fccb81;
 
    .ant-menu-dark{
      background:  #fccb81;
      
      opacity: 1;
      color: black;

     

      .ant-menu-item {
        color: rgb(0, 0, 0);
        margin: auto;

        a {
          color: rgb(0, 0, 0);
          margin: auto;
          font-weight: bold;
          font-size: 11px;
          
        }
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        .ant-menu-submenu-arrow {
          color: rgb(0, 0, 0);
          font-size: large;
         
        }
      }
      color: rgb(0, 0, 0);
      background:  hsl(36, 95%, 75%);
      font-weight: bold;
      font-size: 12px;
      font-weight: bold;
  
     
      .ant-menu {
        background:  #ffe6a0;
        
        color: black;
      .ant-menu-item {
       // background:  #ffe6a0;
       background :  #fef1d1fb;
   
        margin: auto;
        color: rgb(0, 0, 0);
       
        a {
          color: rgb(21, 21, 21);
          
         
         
        }
      }
      }
    }
  }

  .ant-layout-sider-trigger {
   background:  #fccb81;
   // background :  #ff7a3d;
   height:40px;

   border-radius: 10px 10px 0px 0px;
  }
}

.ant-menu-item-selected {
  background-color: rgb(255, 179, 56) !important;
  border-radius: 18px ;
  //border: 2px solid #020100;


   

}
.logo2{
  //height:100px;

  background-color: white;
 
  //animation: roll 0s infinite;
 
  

  
}
.sidebar-format{

  
  margin-top: 60px;
 
  //z-index: 99;
  border-radius: 10px 10px 0px 0px;
 
 margin-bottom: -50px;
 


}
.logo{
  
  
  margin-inline-start: -190px;
  margin-top: 1px;
 

  animation: roll 45s infinite;
 
  
  width:100px;
   height:80px;
}

.header-format{

  
  //background-image:url("/images/webheader.png");
  background:  #fccb81;
 // background :  #ff7a3d;
  border-radius: 10px ; /*rounded corners*/

 margin-inline-start: 195px; 
 
  
 

  top: 0%;
   z-index: 90;
  width:100%;
  position:fixed;
 
    
   
  
}



@keyframes roll {
  0% {
    transform: rotateY(100);
  }
  100% {
    transform: rotateY(360deg);
  }

}
